import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/global.css';
import reportWebVitals from './reportWebVitals';
import Loadable from 'react-loadable';
import LoadingComponent from "./components/loader";

const App = Loadable({
  loader: () => import('./App'),
  loading: LoadingComponent,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
