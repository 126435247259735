import React from "react";
import loader from "../asset/image/loader.png";
import { motion } from "framer-motion";

function LoadingComponent(): JSX.Element {
  const scaleVariants = {
    big: { scale: 1.2 },
    small: { scale: 1 },
  };
  return (
    <div className="loader bg-white h-screen flex  items-center justify-center absolute z-50 w-full">
      <motion.img
        initial="small"
        animate="big"
        variants={scaleVariants}
        transition={{
          duration: 0.5,
          yoyo: Infinity,
        }}
        src={loader}
        alt="loading"
      />
    </div>
  );
}

export default LoadingComponent;
